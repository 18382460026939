<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
         <RadioGroup size="small" v-model="listtype" type="button">
          <Radio
            v-for="(item,index) in typeArray"
            :label="item.id"
            :key="index"
          >{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="query.status"
          placeholder="客户状态"
          clearable
        >
          <Option
            v-for="(status, index) in statuslist"
            :key="index"
            :value="status.value"
            >{{ status.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5" v-if="listtype===2">
        <Select
          v-model="query.companyId"
          size="small"
          placeholder="报备来源"
          clearable
        >
          <Option
            v-for="(channel, index) in channellist"
            :key="index"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5" v-if="listtype===1">
        <Select
          v-model="query.userId"
          placeholder="销售"
          clearable
          size="small"
        >
          <Option
            v-for="user in channelUsers"
            :key="user.id"
            :value="user.id"
            >{{ user.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字：客户名称/投放品牌"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchAdvertisers"
          >搜索</Button
        >
      </i-col>
    </Row>

    <Table
      size="small"
      :columns="isManage ? columnManagedata : columndata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>

  </div>
</template>

<script>
import { invalidAdvertiser } from '@/api/crm/advertiser'
import { getreportbrandbyseller, getreportbrandbyoperation } from '@/api/crm/brand'

import { getTimeDifference } from '@/utils/dateFormat'
import { getSellerPage } from '@/api/os/companyuser'

import {
  formatCustomerStatus,
  formatCustomerDifference
} from '@/utils/tagStatus'

export default {
  data () {
    return {
      statuslist: [
        { value: 1, name: '审批中' },
        { value: 2, name: '审批通过' },
        { value: 5, name: '保护期' },
        { value: 6, name: '已签约' },
        { value: -1, name: '已过期' }
      ],
      channellist: [],
      channelUsers: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15
      },
      tableData: [],
      columndata: [
        { title: '报备品牌', key: 'name' },
        { title: '客户名称', key: 'advertiserName' },
        { title: '报备运营方', key: 'companyName' },
        {
          title: '报备来源',
          key: 'sourceCompanyName',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                params.row.userName === ''
                  ? params.row.sourceCompanyName === null
                    ? '-'
                    : params.row.sourceCompanyName
                  : params.row.sourceCompanyName +
                      '(' +
                      params.row.userName +
                      ')'
              )
            ])
          }
        },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(
              h,
              params.row.status,
              params.row.statusName
            )
          }
        },
        {
          title: '保护倒计时',
          key: 'difference',
          align: 'center',
          render: (h, params) => {
            return formatCustomerDifference(
              h,
              getTimeDifference(Date.now(), params.row.overdueTime)
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.id)
                  }
                }
              },
              '详情'
            )
            return h('div', [detailButton])
          }
        }
      ],
      columnManagedata: [
        { title: '客户名称', key: 'name' },
        { title: '报备品牌', key: 'brands' },
        {
          title: '所属公司/部门/销售',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                params.row.currentUserName === ''
                  ? params.row.currentCompanyName === null
                    ? '-'
                    : params.row.currentCompanyName
                  : params.row.currentCompanyName +
                      '(' +
                      params.row.currentUserName +
                      ')'
              )
            ])
          }
        },
        { title: '客户类型', key: 'typeName' },
        { title: '报备来源', key: 'sourceName' },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(
              h,
              params.row.companyAdvertiserStatus,
              params.row.companyAdvertiserStatusName
            )
          }
        },
        {
          title: '保护倒计时',
          key: 'difference',
          align: 'center',
          render: (h, params) => {
            return formatCustomerDifference(
              h,
              getTimeDifference(Date.now(), params.row.overdueTime)
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.id)
                  }
                }
              },
              '详情'
            )
            const invalidButton = h(
              'a',
              {
                props: {
                  type: 'warning',
                  size: 'small'
                },
                style: { marginRight: '5px', color: '#f87676' },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要标记客户【 ' +
                        params.row.name +
                        '】为无效状态？</p>',
                      loading: true,
                      onOk: () => {
                        invalidAdvertiser({
                          advertiserId: params.row.id,
                          companyId: params.row.currentCompanylId
                        })
                          .then((res) => {
                            if (res && !res.errcode) {
                              this.$Notice.success({ desc: '操作成功' })
                              this.getPageData()
                            }
                            this.$Modal.remove()
                          })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              },
              '标记失效'
            )

            return h('div', [
              detailButton,
              this.invalidAdvertiser &&
              this.allinvalidStatus.includes(params.row.companyAdvertiserStatus)
                ? invalidButton
                : null
            ])
          }
        }
      ],

      allinvalidStatus: [2, 5, 6],
      ChangeCustomerUsermodel: false,
      changeSaler: {
        advertiserId: 0,
        companyId: 0,
        userId: 0
      },

      typeArray: [{
        id: 1, name: '报备的品牌'
      },
      {
        id: 2, name: '审核的品牌'
      }],
      listtype: 1
    }
  },
  created () {
    this.getChannelUserList()
    this.initPageData()
  },
  methods: {
    initPageData () {
      const searchQuery = {
        pageNumber: 1,
        pageSize: 15,
        keyword: this.query.keyword,
        status: this.query.status
      }
      switch (this.listtype) {
        case 1:
          searchQuery.userId = this.query.userId
          getreportbrandbyseller(searchQuery).then(res => {
            this.total = res.totalRow
            this.tableData = res.list
            this.query.pageNumber = res.pageNumber
          })
          break
        case 2:
          getreportbrandbyoperation(searchQuery).then((response) => {
            this.total = response.totalRow
            this.tableData = response.list
            this.query.pageNumber = response.pageNumber
          })
          break
      }
    },
    reloadData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchAdvertisers () {
      this.query.pageNumber = 1

      this.initPageData()
    },
    handleShowDetail (customerId) {
      this.$emit('on-detail', customerId)
    },
    getChannelUserList () {
      const data = {
        companyId: this.$store.getters.token.userInfo.companyId,
        pageNumber: 1,
        pageSize: 1000
      }
      getSellerPage(data).then((response) => {
        this.channelUsers = response.list
      })
    }
  },
  watch: {
    listtype () {
      this.query.pageNumber = 1
      this.initPageData()
    }
  },
  computed: {
    isManage: function () {
      // let startDate = new Date(this.contract.startDate)
      return this.isAuth('Advertiser_Manage_View')
    },
    allowEdit: function () {
      return this.isAuth('Advertiser_Edit')
    },
    adjustAscription: function () {
      // 调整销售
      return this.isAuth('Advertiser_Adjust_Ascription')
    },
    invalidAdvertiser: function () {
      // 调整销售
      return this.isAuth('Advertiser_Invalid')
    }
  }
}
</script>
